var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDisabled!=='true')?_c('li',{class:{
            'breakpointHit': _vm.isBreakpointHit,
    },attrs:{"data-breakpoint-index":_vm.breakpointIndex}},[(_vm.isDebugFeatureEnabled)?_c('span',{class:{
                'breakpointGutter' : true,
                'breakpointControlOff' : ! _vm.hasBreakpoint(_vm.indexObj) && ! _vm.isImportHeader,
                'breakpointControlOn' : _vm.hasBreakpoint(_vm.indexObj),
                'breakpointIndicatorClass' : _vm.hasBreakpoint(_vm.indexObj),
          },attrs:{"title":_vm.hasBreakpoint(_vm.indexObj) ? 'Remove breakpoint.' : 'Add breakpoint.'},on:{"mouseover":_vm.onBkptSwitchMouseOver,"mouseleave":_vm.onBkptSwitchMouseLeave,"click":function($event){$event.stopPropagation();return _vm.doToggle()}}},[(_vm.hasBreakpoint(_vm.indexObj))?[_vm._v("🔴")]:[_vm._v("  ")]],2):_vm._e(),(_vm.isDebugFeatureEnabled)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasGutterOptions),expression:"hasGutterOptions"}],class:{'breakpointGutterOption' : true,
                'breakpointOptionHidden' : _vm.isOptionInitiallyHidden,
               },attrs:{"title":"Additional breakpoints exist in details","data-breakpoint-index":_vm.breakpointIndex}},[_vm._v(_vm._s(_vm.getGutterOptionDisplayString()))]):_vm._e(),_vm._t("default")],2):_c('li',[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }