<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "ChannelView",
        methods: {

        },
        created() {

        },
        computed: {

        },
        watch: {

        },
        props: [ 'channelName']
    }
</script>

<style scoped>

</style>
