import { render, staticRenderFns } from "./ChannelControlPanel.vue?vue&type=template&id=3a9a681b&scoped=true&"
import script from "./ChannelControlPanel.vue?vue&type=script&lang=js&"
export * from "./ChannelControlPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9a681b",
  null
  
)

export default component.exports