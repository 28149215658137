var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dafFlexContainer"},[_vm._l((_vm.displayFieldList),function(propKey,keyIndex){return _c('div',{key:keyIndex,staticClass:"dafFlexItem",attrs:{"data-flex-item":propKey}},[_c('div',[_c('div',[_c('label',{staticClass:"form-label",attrs:{"for":_vm.getFormInputId(propKey),"title":_vm.getEnumTypeShortDefinition(propKey)}},[_vm._v(_vm._s(propKey))]),_c('span',{staticClass:"infoIconLink",attrs:{"title":`Click to open the ${propKey} assert element detailed description in a new browser tab.`},on:{"click":function($event){$event.stopPropagation();return _vm.openHelp(propKey)}}},[_c('img',{staticStyle:{"vertical-align":"top"},attrs:{"alt":"External link","src":require("../../../assets/ext_link.png")}})])]),_c('div',[(_vm.isPropertyAnEnumType(propKey))?[_c('select',{staticClass:"form-control-select",attrs:{"id":_vm.getFormInputId(propKey),"data-prop-key":propKey},domProps:{"value":_vm.getPropVal(propKey)},on:{"change":_vm.onEvalObjPropSelect}},_vm._l((_vm.getEnumTypeArray(propKey)),function(option,idx){return _c('option',{key:idx,attrs:{"title":option.definition,"disabled":option.codeValue===''},domProps:{"value":option.codeValue}},[_vm._v(" "+_vm._s(option.displayName)+" ")])}),0)]:[_c('textarea',{class:{
                       'form-control-textarea-error': _vm.getResultCode().valueOf() !== 'pass' && _vm.getResultPropKey() === propKey,
                       'form-control-textarea' : true,
                       },attrs:{"id":_vm.getFormInputId(propKey),"data-prop-key":propKey},domProps:{"value":_vm.getPropVal(propKey)},on:{"input":_vm.onTextChange}})]],2),_c('div',{staticClass:"smallText"},[_vm._v(_vm._s(_vm.getEnumTypeFormalDefinition(propKey)))])])])}),_vm._v(" "),_c('button',{staticClass:"evalButton",on:{"click":function($event){return _vm.doEval('')}}},[_vm._v("Evaluate")]),_c('div',{class:{
                   'resultBox': true,
                   'evalNotPassed': _vm.getResultCode().valueOf() !== 'pass',
                   }},[_c('span',{staticClass:"form-block"},[_vm._v(_vm._s(_vm.getResultCode()))]),_c('vue-markdown',{attrs:{"source":_vm.getResultMessage()}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }